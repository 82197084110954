footer {
    background-color: #2B4C68;
    font-family: 'Open Sans', sans-serif;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
    width: 100vw;
    bottom: 0;
    left: 0;
    /* transition: 1s; */
    text-decoration: none;
    align-self: flex-end;
    height: 15vh;

}
.footer-left-contact-heading{
    font-weight: 600;
}
.footer-left-contact-email{
    line-height: 0.5;
    padding-bottom: 10px;
}

.footer-right{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: flex-end;
}

.footer-right a{
    color: inherit;
}

.footer-right a:hover{
    color: #aed4f5;
}


@media (min-width: 600px) and (max-width: 1024px){
    .footer-left{
        margin-left: 3rem;
    }
    .footer-right{
        margin-right: 3rem;
    }
    
}

@media (min-width: 1024px){
    .footer-left{
        margin-left: 4rem;
    }
    .footer-right{
        margin-right: 6rem;
    }
    
}