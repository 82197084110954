.timeline {
    background: rgb(43, 76, 104);
    background: linear-gradient(0deg, rgb(43, 76, 104) 32%, rgba(137, 190, 236, 0) 100%);
}

.timeline-title {
    font-size: 1em;
}

.vertical-timeline-element-title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 600;
    font-size: 1.6em;
    color: #2B4C68;
}

.vertical-timeline-element-title2 {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 600;
    font-size: 1.6em;
    color: rgb(235, 235, 235);

}

.vertical-timeline-element-subtitle,
#description,
.date {
    font-family: 'Open Sans', sans-serif;
    font-weight: 100;
    font-size: 1em;
    color: #000000;
    /* text-shadow: -0.5px 0 rgb(0, 0, 0), 0 0.5px rgb(0, 0, 0), 0.5px 0 rgb(0, 0, 0), 0 -0.5px rgb(0, 0, 0); */
}
.vertical-timeline-element-subtitle2,#description2,
.date2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 100;
    font-size: 1em;
    color: rgb(235, 235, 235);
    /* text-shadow: -0.5px 0 rgb(0, 0, 0), 0 0.5px rgb(0, 0, 0), 0.5px 0 rgb(0, 0, 0), 0 -0.5px rgb(0, 0, 0); */
}
h3 {
    padding-top: 0.25em;
}

.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0), 0 0.4em 1.25em rgba(0, 0, 0, 0) !important;
    padding: 0em 3em 1em 3em !important;
    background-color: rgba(0, 0, 0, 0);
}

.timeline-fim,
.timeline-inicio {
    display: flex;
    justify-content: center;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 100;
    font-size: 2em;
    color: black;
}
.timeline-inicio{
    background-color:#2B4C68;
    margin-bottom: 0;
    color: white;

}
.vertical-timeline-element-content-arrow {
    display: none;
}

.vertical-timeline-element-icon {
    /* display: none; */
}


@media (max-width:800px) {

    .timeline-fim,
    .timeline-inicio {
        display: flex;
        justify-content: left;
        padding: 5px;

    }
}