.area-description{
    font-family: 'Open Sans', sans-serif;
    color: #262522;
    font-weight: 100;
    font-size: 0.9em;
    display: flex;
    flex-wrap: nowrap;
    padding: 0px 10px;
    text-align: center;
    margin-top: 0;
}

.area-description-short{
    font-size: 0.8em;

}

@media (min-width: 600px){
    .area-description-short{
        font-size: 1em;
    
    }
}