.area-atuação .area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
}
.area-atuação{
    margin: 6rem 3rem 0 3rem;

}

.area-atuação .area-text {
    border: 2px solid #2B4C68;
    border-radius: 0 10px 10px 10px;
    margin-left: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
}

.area-atuação-heading {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 100;
    font-size: 3em;
    color: #2B4C68;
}

.area-atuação .area-title {
    justify-content: left;

}

.area-atuação .area-description {
    text-align: left;

}

.area-atuação .area-img {
    width: 20vw;
    height: 20vh;
    border: 2px solid #2B4C68;
    border-radius: 10px 0 10px 10px;
}

.area-atuação .area-img img {
    width: 20vw;
    height: 20vh;
    object-fit: contain;
}

@media (min-width: 600px) and (max-width: 1024px) {
    .area-atuação{
  margin: 6rem 5rem 0 5rem;

  }
}

@media (min-width: 1024px){
    .area-atuação{

    margin: 6rem 10rem 0 10rem;
  
    } 
}