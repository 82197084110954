.opa {
    display: flex;
    flex-direction: column;
    margin: 2rem 3rem 0 3rem;

}

.opa-left{
    display: flex;
    flex-direction: column;

}
.opa-logo, .opa-flyer {
    width: 300px;
    height: 300px;
    /* align-self: center; */
    
}

.opa-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.opa-heading {
    align-content: center;
    justify-content: center;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 100;
    font-size: 3em;
    color: #2B4C68;
    margin: 0px;

}

.opa-text {
    align-content: center;
    justify-content: center;
    margin-bottom: 1rem;
}
.opa-logo{
    /* height: 150px; */
    object-fit: contain;
}
.contactos-opa{
    margin-bottom: 2rem;
}

.contactos-opa-heading{
    font-family: 'Open Sans', sans-serif;
    color: #2B4C68;
}
.opa-long-text, .opa-short-text, .email, .instagram{
    font-family: 'Open Sans', sans-serif;
    color: #262522;
}

.contactos-opa-heading{
    font-size: 1.2em;
    margin-bottom: 2px;
}
.email, .instagram{
    height: 40px;
}
/* .email-p:hover, .instagram-p:hover{
    text-decoration: underline;
    color: #2B4C68;
} */

.btn-inscrever {
    background: #2B4C68;
    border-radius: 20px;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 200px;
    height: 38px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    /* margin-right: 4px; */

}

.btn-inscrever:hover {
    background: #457299;
    font-weight: 600;
    transition: 0.5s;


}

.btn-contacto {
    background-color: white;
    border-radius: 20px;
    color: #2B4C68;
    padding: 10px;
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    /* border: 2px solid #2B4C68; */
    cursor: pointer;
    /* width: 200px;
    height: 38px; */
    margin-bottom: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */

}

.btn-contacto:hover {
    border: none;
    font-weight: 600;
    color: #457299;
    /* background-color: #d1e9ff; */

}
p a {
    display: inline;
}
@media (min-width: 750px){
    .opa {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-evenly;
    }
  
    .opa-heading{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .opa-buttons {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: flex-start;
        position: relative;
       
    }

    .btn-inscrever {
        margin-bottom: 10px;
        margin-right: 10px;
    }
    .opa-left{
        justify-content: flex-start;
    }
    .opa-right {
        margin: 1rem 0rem 1rem 2rem;
    }

    .opa-text {
        margin-bottom: 1rem;
    }

}

@media (min-width: 600px) and (max-width: 1024px) {
    .opa{
    margin: 5rem 5rem 1rem 5rem;
  
    }
  }
  
  @media (min-width: 1024px){
    .opa{
      margin: 5rem 10rem 1rem 10rem;
    
      } 
  }
