
a{
    color: #2B4C68;
    text-decoration: none;
    display: flex;
    flex-direction: row;
}
a p{
    color: #262522;
}

.contacto{
    margin-bottom: 2rem;
}
.contacto-img{
    width: 260px;
    height: 250px;
    object-fit: cover;
}

.contacto-text{
    font-family: 'Open Sans', sans-serif;
    color: #262522;

}

.contacto-title{
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 100;
    font-size: 2em;
    color: #2B4C68;
}
.email, .instagram, .linkedin{
    display: flex;
    flex-direction: row;
    
}

.email p, .instagram p, .linkedin p{
    padding-left: 5px;
}

.icon{
    position: relative;
    top: 10px;
} 
.linkedin{
    padding-left: 2px;
}
.linkedin-p{
    margin-left: 5px;
}

@media (min-width: 800px) and (max-width: 1300px){
    .contacto{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    } 
}

@media (min-width: 1200px){
    .contacto{
        display: flex;
        flex-direction: row;
        margin-right: 5rem;

    }
    .contacto-text{
        margin-left: 1rem;
    }
}
