.question {
    background-color: #EAEAEA;
    padding: 10px;
    display: flex;
    flex-direction: column;
    /* margin: 20px 0; */
    margin: 1rem 3rem 1rem 3rem;

}

.question-heading{
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 100;
    font-size: 2em;
    color: #2B4C68;
    margin: 10px;
    padding: 5px 10px 0px 10px;
}

.question-text{
    font-family: 'Open Sans', sans-serif;
    color: #262522;
    font-weight: 200;
    margin: 10px;
    padding: 0px 10px 0px 10px;
}
.question-btn {
    align-self: center;
    background: #2B4C68;
    border-radius: 20px;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 200px;
    height: 38px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin: 10px 15px;
    transition: 0.5s;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;

}

.question-btn:hover{
    background: #457299;
    font-weight: 600;
}

@media (min-width: 650px) {
    .question-btn {
    align-self: flex-start;

    }
}

@media (min-width: 600px) and (max-width: 1024px) {
    .question{
  margin: 0 5rem 2rem 5rem;

  }
}

@media (min-width: 1024px){
    .question{

    margin: 0 10rem 2rem 10rem;
  
    } 
}