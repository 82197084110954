header {
    background: rgb(181, 199, 216);
    background: linear-gradient(0deg, rgb(181 199 216 / 22%) 13%, rgba(181, 199, 216, 1) 100%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 4rem;
  margin-top: 4.5rem;

    /* margin-top: 0 !important; */
    /* top: -5; */
}

.header-title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 100;
    font-size: 4em;
    color: #2B4C68;
    padding-top: 10rem;
    margin-bottom: 0;
}

.header-subtitle {
    font-family: 'Open Sans', sans-serif;
    color: #2B4C68;
    font-weight: 300;
    font-size: 1.5em;
    margin-top: 0;

}

.header-left {
    text-align: center;
    padding-bottom: 1rem;
}

.header-btn {
    background: #2B4C68;
    border-radius: 20px;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 160px;
    height: 38px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    /* margin-right: 4px; */

}

.header-btn:hover {
    background: #457299;
    font-weight: 600;
    transition: 0.5s;
}

.header-right{
    display: none;
    /* width: 200px; */
}
.header-left {
    animation: slideIn 1s;
  }

/* code for andre-1 img */
.header-img{
  object-fit: cover;
  width: 400px;
  margin: 3rem 0 0 3rem;
  height: 27rem;
}

/* code for andre-2 img */
/* .header-img{
  object-fit: cover;
  width: 400px;
  margin-top: 2rem;
  margin-bottom: 0rem;
  height: 31rem;
  display: flex;
  justify-content: flex-start;
} */

@media (min-width: 700px) {
    .header-left {
        text-align: end;
    }
    .header-right{
        display:block;
    }
    .header-title {
        padding-top: 15rem;
    }
}

  

   
  
  

  