.contactos-container{
  margin-top: 6rem;
}
.contactos{
    display: flex;
    flex-direction: column;
    margin: 0 3rem 0 3rem;

}
.contactos-heading{
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 100;
    font-size: 3em;
    color: #2B4C68;
    margin: 2rem 3rem 1rem 3rem;
    /* padding-top: 20px; */
}

@media (max-width: 400px) {
    .scroller-container {
      overflow-x: scroll;
    }
  
    .contactos {
      flex-wrap: nowrap;
    }
  
    .email-icon {
      display: flex;
    }
  }
  
@media (min-width: 800px){
    .contactos{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* flex-wrap: wrap; */

    }   
}

@media (min-width: 600px) and (max-width: 1024px) {
    .contactos, .contactos-heading{
  margin: 2rem 5rem 1rem 5rem;

  }
}

@media (min-width: 1024px){
    .contactos, .contactos-heading{

    margin: 2rem 10rem 1rem 10rem;
  
    } 
    .scroller-container {
        overflow-x: auto;
        white-space: nowrap;
      }
}