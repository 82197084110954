.navbar {
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: rgb(181, 199, 216);
    color: white;
    font-family: 'Open Sans', sans-serif;
    gap: 2rem;
    font-weight: 400;
    padding: 0.5rem 1rem;
    /* position: sticky; */
    top: 0;
    left: 0;
    z-index: 1;
}

.navbar-logo {
    font-size: 2rem;
    margin: .5rem .5rem .5rem 4rem;
    color: white;
    z-index: 20;

}

.navbar-links {
    height: 100%;
}

.navbar-links ul {
    display: flex;
    margin: 0 3rem 0 0;
    padding: 0;
    text-align: center;

}

.navbar-links li {
    list-style: none;
}

.navbar-links li:hover {
    font-weight: 600;
}


.navbar-links li a {
    display: block;
    text-decoration: none;
    color: white;
    padding: 0rem 1rem 0.5rem 1rem;
}


.active1 {
    border-bottom: 2px solid white;
    font-weight: bold;
    /* width: 50px; */
    transition: 0.5s;
    /* background-color: rgb(201, 219, 237); */
}

.toggle-button {
    position: absolute;
    cursor: pointer;
    top: .75rem;
    right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
}

.toggle-button .bar {
    height: 3px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
}

@media (max-width: 800px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
        /* background: rgb(117, 139, 159) */
    }

    .toggle-button {
        display: flex;
        margin-top: 0.5rem;
    }

    .navbar-logo {
        margin: .5rem .5rem .5rem 2rem;

    }

    .navbar-links {
        display: none;
        width: 100%;
    }
   
    .navbar-links ul {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        margin: 0 0rem 0 0;
    }

    .navbar-links ul li {
        text-align: center;

    }

    .navbar-links ul li a {
        padding: .5rem 0.6rem;
    }

    .navbar-links.active {
        display: flex;

    }
}