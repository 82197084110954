.sobre-andre, .sobre-trabalho{
    /* margin: 0 !important; */
    margin: 4rem 3rem 0 3rem;
}

.sobre-andre {
    display: flex;
    flex-direction: column-reverse;
    }
.sobre-andre-heading {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 100;
    font-size: 3em;
    color: rgb(43, 76, 104);
    margin-top: 1rem;
   

}
.sobre-andre-text, .sobre-trabalho-texto{
    font-family: 'Open Sans', sans-serif;
    margin-right: 20px;

}
.sobre-andre-img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.sobre-trabalho{
    margin-top: 2.5rem;
}
.sobre-trabalho-heading{
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 100;
    font-size: 2em;
    color: #2B4C68;
    display: flex;
    justify-content: center;

}
.sobre-trabalho-info{
    display: flex;
    flex-direction: column;
}

.sobre-trabalho-logos{
    display: flex;
    flex-direction: column;
    
}

.logo{
    width: 150px;
    height: 100px;
    object-fit: contain;
}

@media (min-width: 800px) {
    .sobre-andre {
        display: flex;
        flex-direction: row;
    }

    .sobre-andre-img {
        width: 25rem;
        height: 25rem;
    }
    .sobre-trabalho-info{
        display: flex;
        flex-direction: row;

    }
    .sobre-trabalho-logos{
        display: flex;
        flex-direction: row;
    }
    .logo{
        margin-right: 10px;
    }
}

@media (min-width: 600px) and (max-width: 1024px) {
    .sobre-andre {
  margin: 6rem 5rem 0 5rem;

  }
 .sobre-trabalho{
    margin: 2rem 5rem 0 5rem;
  
    }
}

@media (min-width: 1024px){
    .sobre-andre{

    margin: 6rem 10rem 0 10rem;
  
    } 
    .sobre-trabalho{

        margin: 2rem 10rem 0 10rem;
      
        } 
}